<template>
    <CommonReply/>
</template>

<script>
    import CommonReply from "@/components/common/CommonReply.vue";
    export default {
        name: "Breply",
        components: {
            CommonReply
        }
    }
</script>

<style scoped>

</style>
